<script setup>
</script>

<template>
  <svg viewBox="0 0 26 20" width="26" height="20">
    <path d="M24.7 3.4C24.5 2 23.4 1 22.1.7 20.2.3 16.7 0 12.9 0 9.1 0 5.5.3 3.6.7 2.4 1.1 1.3 2 1 3.4.8 5 .6 7.2.6 10s.2 5 .5 6.6c.2 1.4 1.3 2.4 2.6 2.7 2 .4 5.5.7 9.3.7 3.8 0 7.3-.3 9.3-.7 1.3-.3 2.4-1.2 2.6-2.7.2-1.6.5-3.8.6-6.6-.2-2.8-.5-5-.8-6.6zm-14.9 11V5.6l7 4.4-7 4.4z"/>
  </svg>
</template>

<style lang="postcss" scoped>

</style>
  