import gsap from 'gsap'
import { CustomEase } from 'gsap/all'
gsap.registerPlugin(CustomEase)

const state = {
  duration: .75,
  ease: CustomEase.create("custom", "0.25, 0, 0, 1"),
}

let overlay = null
let fadeTl = gsap.timeline({ paused: true })

function onBeforeEnter(el, style) {
  style.value = { opacity: 0 }
}

/**
 * Executes the page transition animation when entering a page.
 * 
 * @param {HTMLElement} el - The element being transitioned.
 * @param {Object} style - The style object to be updated during the transition.
 * @returns {Promise} - A promise that resolves when the transition animation is complete.
 */
function onEnter(el, style) {
  return new Promise(async resolve => {
    document.querySelector('.overlay-wipe')?.remove()

    overlay = document.createElement('div')
    overlay.classList.add('overlay-wipe')

    fadeTl.set(overlay, {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      top: 0,
      left: 0,
      zIndex: 19000,
      opacity: 0,
      backgroundColor: 'var(--loud-indigo)',
    })

    document.body.append(overlay)

    fadeTl.to(overlay, {
      duration: state.duration,
      ease: state.ease,
      opacity: 1,
      onComplete: () => {
        style.value = { opacity: 1 }
        useEvent('v-view-unpause')

      },
    }).to(overlay, {
      duration: state.duration,
      ease: state.ease,
      opacity: 0,
      onComplete: () => {
        resolve()
      },
    })
    fadeTl.play()
  })
}

function onAfterEnter(el, style) {
  style.value = null
  overlay.remove()
}

export default {
  onBeforeEnter,
  onEnter,
  onAfterEnter,
}