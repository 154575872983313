/**
 * Custom composable function for handling page load animation.
 *
 * @param {Ref} el - The reference to the DOM element.
 * @param {Object} options - The options object.
 * @param {Object} options.pageLoads - The page load configurations.
 * @param {Object} options.errorPageLoad - The error page load configuration.
 * @param {Object} options.defaultPageLoad - The default page load configuration.
 * @returns {Object} - The style and pageLoadComplete properties.
 */
export default (el, { pageLoads = {}, errorPageLoad = {}, defaultPageLoad = {} } = {}) => {
    const router = useRouter()
    const error = useError()
    const errorLoad = ref(false)
    const pageLoadComplete = ref(false)
    useEvent('v-view-pause')
    const getPageLoad = route => {
        watch(
            error,
            () => {
                if (error.value) errorLoad.value = true
            },
            { immediate: true }
        )
        return errorLoad.value ? pageLoads['error'] : pageLoads[route] || defaultPageLoad
    }

    const currentRoute = computed(
        () =>
            router
                .getRoutes()
                .find(({ name }) => name === router.currentRoute.value.name)?.name
    )
    const style = ref(null)
    const currentPageLoad = getPageLoad(currentRoute.value)
    currentPageLoad.onBeforeEnter(el.value, style)
    // onServerPrefetch(() => {
    //   // init setup, before the dom is painted
    // })

    onMounted(async () => {
        if (!process.client) return

        // the transition
        await currentPageLoad.onEnter(el.value, style)

        // after transition, individual clean up
        currentPageLoad.onAfterEnter(el.value, style)

        // after everything, global clean up
        style.value = null
        pageLoadComplete.value = true
    })

    return { style, pageLoadComplete }
}