<script setup>
</script>

<template>
  <svg viewBox="0 0 19 20" width="19" height="20">
    <path d="M4.3 19.6h-4v-13h4v13zm-2-14.8c-1.1 0-2-1-2-2.2 0-1.2.9-2.2 2-2.2s2 1 2 2.2c0 1.2-.9 2.2-2 2.2zm16.4 14.8h-3.9v-6.3c0-1.5 0-3.5-2-3.5s-2.3 1.6-2.3 3.4v6.5H6.7V6.5h3.7v1.8h.1c.5-1 1.8-2.1 3.6-2.1 3.9 0 4.6 2.7 4.6 6.2v7.2z"/>
  </svg>
</template>

<style lang="postcss" scoped>

</style>
  