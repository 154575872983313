<script setup>
</script>

<template>
  <svg viewBox="0 0 19 20" width="19" height="20">
    <path d="M5.7.1c-1 0-1.7.2-2.3.4-.6.3-1.1.6-1.6 1.2S1 2.8.8 3.5C.5 4.1.4 4.8.3 5.9.3 7 .2 7.3.3 10s0 3.1.1 4.1c0 1.1.2 1.8.4 2.4.2.7.6 1.2 1.1 1.8.5.6 1 .9 1.6 1.2.6.2 1.3.4 2.2.5h3.8c2.5 0 2.8 0 3.8-.1s1.6-.2 2.2-.5c.6-.3 1.1-.6 1.6-1.2s.8-1.1 1.1-1.8c.2-.6.4-1.4.4-2.4 0-1.1.1-1.4 0-4.1s0-3.1-.1-4.1c0-1.1-.2-1.8-.4-2.4-.2-.7-.6-1.2-1.1-1.8S16 .7 15.4.5c-.6-.2-1.3-.4-2.2-.5H9.5C7 0 6.7 0 5.7.1zm.1 18c-.9 0-1.4-.2-1.7-.3-.4-.2-.7-.4-1.1-.7s-.5-.7-.7-1.1c-.1-.4-.3-1-.3-1.9 0-1.1-.1-1.4-.1-4v-4c0-1 .2-1.5.3-1.9.3-.5.5-.9.8-1.2.3-.4.6-.6 1-.8.3-.1.8-.3 1.7-.3 1-.1 1.3-.1 3.7-.1 2.5 0 2.8 0 3.7.1.9 0 1.4.2 1.7.3.4.2.7.4 1.1.7.3.3.5.7.7 1.1.2.4.4 1 .4 1.9 0 1.1.1 1.4.1 4v4c0 1-.2 1.5-.3 1.9-.2.5-.4.8-.7 1.2-.3.4-.6.6-1.1.8-.3.1-.8.3-1.7.3-1 0-1.3.1-3.7.1-2.5 0-2.8 0-3.8-.1m7.5-13.4c0 .2.1.5.2.7.1.2.3.4.5.4.2.1.4.1.6.1.2 0 .4-.2.6-.3.2-.2.3-.4.3-.6 0-.2 0-.5-.1-.7 0-.3-.2-.5-.4-.6-.2-.1-.4-.2-.6-.2-.3 0-.6.1-.8.4-.2.1-.3.4-.3.8zM4.8 10c0 2.8 2.1 5.1 4.7 5.1s4.7-2.3 4.7-5.1-2.1-5.1-4.7-5.1S4.8 7.2 4.8 10zm1.6 0c0-.7.2-1.3.5-1.9.3-.5.8-1 1.4-1.2.6-.3 1.2-.3 1.8-.2.6.1 1.1.4 1.6.9.4.5.7 1.1.8 1.7.1.6.1 1.3-.2 1.9-.2.6-.6 1.1-1.1 1.5-.5.4-1.1.6-1.7.6-.4 0-.8-.1-1.2-.3-.4-.2-.7-.4-1-.7-.3-.3-.5-.7-.7-1.1-.1-.3-.2-.8-.2-1.2z"/>
  </svg>
</template>

<style lang="postcss" scoped>

</style>
  